

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.container-vrc{
    background-color: #0e2479;
    display: flex;
    justify-content: center;
}
.cont-img-background{
    display: flex;
    position: fixed;
    z-index: 0;
    width: 100%;
}
.cont-img-background img{
    max-width: 120%;
    opacity: 0.4;
}

.container-img-principal-vrc{
    z-index: 2;
    position: absolute;
    top: -132px;
    background-color: #41281e;
    border-radius: 100%;
    border:4px solid #fff;
    width: 150px;
    height: 150px;
    overflow: hidden;
}

.container-img-principal-vrc img{
    max-width: 100%;
}

.content-data{
    z-index: 1;
    width: 100%;
    padding: 30px;
    background-color: #fff0eb;
    margin-top: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 290px);
    justify-content: flex-start;
    position: relative;
            
}

.content-data p{
    font-size: 16px;
    text-align: center;
    color: #444;
    margin: 25px 0;
}


.personal-data h1{
    color: #444;
    font-size: 30px;
    text-align: center;
    margin: 0;

}

.personal-data .cargo-vrc{
    color: #444;
    font-size: 18px;
    text-align: center;
    margin: 5px 0;
}

.personal-data .empresa-vrc{
    color: #777;
    font-size: 15px;
    text-align: center;
    margin: 10px 0 5px;

}

.personal-data .description-vrc{
    color: #999;
    font-size: 14px;
    text-align: center;
    margin: 30px 0 30px;

}



.contact-data .container-personal-vrc{
    width: 250px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    
}

.container-personal-vrc .cont-square-media-vrc{
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: #444 solid 2px;
    border-radius: 8px;
    color: #444;
    
}
.cont-square-media-vrc:hover{
    color: #fff;
    background-color: #444;
}